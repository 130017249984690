import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Icons
import kalimartLogo from "../../assets/icons/kalimart-logo.png";

// CSS
import "./navbar.css";

// constant
import { TABLET_VIEWPORT } from "../../constant/viewport";

// Util
import util from "../../util/lib";

// Components
import Searchbar from "../core/Searchbar";

const Navbar = () => {
  const [mobileSearchBar, setMobileSearchBar] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      const currentScrollPositon = window.pageYOffset;
      if (currentScrollPositon > 105) {
        setMobileSearchBar(true);
      } else {
        setMobileSearchBar(false);
      }
    };
  });

  return (
    <React.Fragment>
      <nav className="container-fluid pl-2 pr-2 nav-color nav-bar-style">
        <div className="d-flex">
          <span className="icon-menu-bar mr-2 ml-2 pt-1 cursor-pointer">
            <i className="fa fa-bars fa-lg" aria-hidden="true"></i>
          </span>
          <Link to="/">
            <img
              src={kalimartLogo}
              height="33px"
              className="ml-1 mt-n1"
              alt="kalimart.in"
            />
          </Link>
          <div className="w-100 ml-3 mr-3">
            {util.getViewport() > TABLET_VIEWPORT && (
              <Searchbar
                width="75"
                align="center"
                placeholder="Search for products..."
              />
            )}
          </div>
          <span className="nav-cart-section cursor-pointer pt-1 mr-2">
            <i
              className="fa fa-shopping-cart fa-lg icon-cart mr-2"
              aria-hidden="true"
            ></i>
            <p className="d-inline-block nav-cart-text m-0">Cart</p>
          </span>
        </div>
        {util.getViewport() <= TABLET_VIEWPORT && (
          <div className="pt-3">
            <Searchbar placeholder="Search for products..." />
          </div>
        )}
      </nav>
      {util.getViewport() <= TABLET_VIEWPORT && (
        <nav
          className={[
            "nav-search-bar__mobile",
            !mobileSearchBar ? "d-none" : "",
          ].join(" ")}
        >
          <div className="d-flex">
            <span className="icon-menu-bar mx-3 pt-1 cursor-pointer">
              <i className="fa fa-bars fa-lg" aria-hidden="true"></i>
            </span>
            <div className="w-100">
              <Searchbar placeholder="Search for products..." />
            </div>
            <span className="cursor-pointer pt-1 mx-3">
              <i
                className="fa fa-shopping-cart fa-lg icon-cart"
                aria-hidden="true"
              ></i>
            </span>
          </div>
        </nav>
      )}
    </React.Fragment>
  );
};

export default Navbar;
