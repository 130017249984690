import React from "react";
import PropTypes from "prop-types";
import SubHeading from "../SubHeading";

// CSS
import "./heading.css";

const Heading = ({ label, subHeading, ...props }) => {
  return (
    <div className={props.className}>
      <h2 className="heading">{label}</h2>
      {subHeading && <SubHeading>{subHeading}</SubHeading>}
    </div>
  );
};

Heading.propTypes = {
  label: PropTypes.string.isRequired, // Should not more then 25 character
  subHeading: PropTypes.string,
};

export default Heading;
