import React from "react";

// Components
import MasterBanner from "../../components/MasterBanner";
import ProductCategory from "../../components/ProductCategory";

// Services
import { productCategoryData } from "../../services/ProductCategoryData";

//Banner image
import bannerImage from "../../assets/image/master-banner/banner1.jpg";
import bannerImage2 from "../../assets/image/master-banner/banner2.png";

class LandingPage extends React.Component {
  render() {
    return (
      <div className="km-container">
        <MasterBanner data={[bannerImage, bannerImage2, 2]} />
        <div className="px-0 pt-0">
          <div>
            {productCategoryData.length > 0 &&
              productCategoryData.map((productCategory) => (
                <ProductCategory
                  key={productCategory.id}
                  name={productCategory.name}
                  image={productCategory.image}
                  categories={productCategory.category}
                />
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default LandingPage;
