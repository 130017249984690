// Grocery & Staples
import cookingOils from "../assets/image/product-category/cooking-oils.webp"
import dryFruitsNuts from "../assets/image/product-category/dry-fruits-nuts.webp"
import gheeVanapati from "../assets/image/product-category/ghee-vanaspati.webp"
import puls from "../assets/image/product-category/puls.webp"
import rice from "../assets/image/product-category/rice-other-grains.webp"
import salt from "../assets/image/product-category/salt-sugar.webp"
import spices from "../assets/image/product-category/spices.webp"

// Personal Care
import bath from "../assets/image/product-category/bath-body.webp"
import cosmetics from "../assets/image/product-category/cosmetics.webp"
import faceCare from "../assets/image/product-category/face-care.webp"
import feminine from "../assets/image/product-category/feminine-hygiene.webp"
import fragrances from "../assets/image/product-category/fragrances.webp"
import hairCare from "../assets/image/product-category/hair-care.webp"
import health from "../assets/image/product-category/health-and-wellness.webp"
import skinCare from "../assets/image/product-category/skin-care.webp"
import oralCare from "../assets/image/product-category/oral-care.webp"
import grooming from "../assets/image/product-category/men's-grooming.webp"
import newLaunches from "../assets/image/product-category/new-launches.webp"

// Household Items
import tissuesDisposables from "../assets/image/product-category/tissuesDisposables.webp";
import cleaners from "../assets/image/product-category/cleaners.webp";
import dishwashers from "../assets/image/product-category/dishwashers.webp";
import homeCarFresheners from "../assets/image/product-category/homeCarFresheners.webp";
import laundryDetergents from "../assets/image/product-category/laundryDetergents.webp";
import poojaNeeds from "../assets/image/product-category/poojaNeeds.webp";
import repellents from "../assets/image/product-category/repellents.webp";
import shoeCare from "../assets/image/product-category/shoeCare.webp";

// Biscuits, Snacks & Chocolates
import chocolates from "../assets/image/product-category/chocolates.webp";
import biscuitsCookies from "../assets/image/product-category/biscuitsCookies.webp";

// Beverages
import waterSoda from "../assets/image/product-category/waterSoda.webp";
import teaCoffee from "../assets/image/product-category/teaCoffee.webp";
import milkDrinks from "../assets/image/product-category/milkDrinks.webp";
import juicesDrinks from "../assets/image/product-category/juicesDrinks.webp";
import healthEnergyDrinks from "../assets/image/product-category/healthEnergyDrinks.webp";
import coldDrinks from "../assets/image/product-category/coldDrinks.webp";

// Breakfast & Dairy
import milkProducts from "../assets/image/product-category/milkProducts.webp";
import jamsHoneySpreads from "../assets/image/product-category/jamsHoneySpreads.webp";
import butterCheese from "../assets/image/product-category/butterCheese.webp";
import breakfastCerealMixes from "../assets/image/product-category/breakfastCerealMixes.webp";

// Noodles, Sauces & Instant Food
import saucesKetchups from "../assets/image/product-category/saucesKetchups.webp";
import noodlesSaucesBestOffers from "../assets/image/product-category/noodlesSaucesBestOffers.webp";
import jamsHoney from "../assets/image/product-category/jamsHoney.webp";
import newLaunchesNoodles from "../assets/image/product-category/newLaunches.webp";
import noodlesVermicelli from "../assets/image/product-category/noodlesVermicelli.webp";
import readyMadeMealsMixes from "../assets/image/product-category/readyMadeMealsMixes.webp";
import picklesChutneys from "../assets/image/product-category/picklesChutneys.webp";
import bakingDessertItems from "../assets/image/product-category/bakingDessertItems.webp";

// Baby Care
import diapersWipes from "../assets/image/product-category/diapersWipes.webp";
import bBabyFood from "../assets/image/product-category/bBabyFood.webp";
import babySkinHairCare from "../assets/image/product-category/babySkinHairCare.webp";
import babyAccessoriesMore from "../assets/image/product-category/babyAccessoriesMore.webp";

export const productCategoryData = [
    {
        id: 1,
        name: "Grocery & Staples",
        image: "",
        category: [
            {
                id: 1,
                name: "Pulses",
                image: puls,
                offer: 8
            },
            {
                id: 2,
                name: "Cooking Oils",
                image: cookingOils,
                offer: 30
            },
            {
                id: 3,
                name: "Ghee & Vanaspati",
                image: gheeVanapati,
                offer: 10
            },
            {
                id: 4,
                name: "Rice & Other Grains",
                image: rice,
                offer: 33
            },
            {
                id: 5,
                name: "Salt & Sugar",
                image: salt,
                offer: 22
            },
            {
                id: 6,
                name: "Spices",
                image: spices,
                offer: 30
            },
            {
                id: 7,
                name: "Dry Fruits & Nuts",
                image: dryFruitsNuts,
                offer: 36
            },
        ]
    },
    {
        id: 2,
        name: "Personal Care",
        image: "",
        category: [
            {
                id: 1,
                name: "Bath & Body",
                image: bath,
                offer: 11
            },
            {
                id: 2,
                name: "Hair Care",
                image: hairCare,
                offer: 20
            },
            {
                id: 3,
                name: "Skin Care",
                image: skinCare,
                offer: 25
            },
            {
                id: 4,
                name: "Oral Care",
                image: oralCare,
                offer: 19
            },
            {
                id: 5,
                name: "Fragrances",
                image: fragrances,
                offer: 36
            },
            {
                id: 6,
                name: "Face Care",
                image: faceCare,
                offer: 32
            },
            {
                id: 7,
                name: "Feminine Hygiene",
                image: feminine,
                offer: 10
            },
            {
                id: 8,
                name: "Health And Wellness",
                image: health,
                offer: 21
            },
            {
                id: 9,
                name: "Cosmetics",
                image: cosmetics,
                offer: 0
            },
            {
                id: 10,
                name: "Men's Grooming",
                image: grooming,
                offer: 10
            },
            {
                id: 11,
                name: "New Launches",
                image: newLaunches,
                offer: 11
            },
        ]
    },
    {
        id: 3,
        name: "Household Items",
        image: "",
        category: [
            {
                id: 1,
                name: "Laundry Detergents",
                image: laundryDetergents,
                offer: 14
            },
            {
                id: 2,
                name: "Dishwashers",
                image: dishwashers,
                offer: 12
            },
            {
                id: 3,
                name: "Cleaners",
                image: cleaners,
                offer: 19
            },
            {
                id: 4,
                name: "Repellents",
                image: repellents,
                offer: 25
            },
            {
                id: 5,
                name: "Pooja Needs",
                image: poojaNeeds,
                offer: 9
            },
            {
                id: 6,
                name: "Home & Car Fresheners",
                image: homeCarFresheners,
                offer: 22
            },
            {
                id: 7,
                name: "Tissues & Disposables",
                image: tissuesDisposables,
                offer: 15
            },
            {
                id: 8,
                name: "Shoe Care",
                image: shoeCare,
                offer: 10
            },
        ]
    },
    {
        id: 4,
        name: "Biscuits, Snacks & Chocolates",
        image: "",
        category: [
            {
                id: 1,
                name: "Chocolates",
                image: chocolates,
                offer: 30
            },
            {
                id: 2,
                name: "Biscuits & Cookies",
                image: biscuitsCookies,
                offer: 10
            },
        ]
    },
    {
        id: 5,
        name: "Beverages",
        image: "",
        category: [
            {
                id: 1,
                name: "Cold Drinks",
                image: coldDrinks,
                offer: 16
            },
            {
                id: 2,
                name: "Juices & Drinks",
                image: juicesDrinks,
                offer: 9
            },
            {
                id: 3,
                name: "Tea & Coffee",
                image: teaCoffee,
                offer: 14
            },
            {
                id: 4,
                name: "Health & Energy Drinks",
                image: healthEnergyDrinks,
                offer: 16
            },
            {
                id: 5,
                name: "Water & Soda",
                image: waterSoda,
                offer: 11
            },
            {
                id: 6,
                name: "Milk Drinks",
                image: milkDrinks,
                offer: 18
            },
        ]
    },
    {
        id: 6,
        name: "Breakfast & Dairy",
        image: "",
        category: [
            {
                id: 1,
                name: "Milk & Milk Products",
                image: milkProducts,
                offer: 10
            },
            {
                id: 2,
                name: "Butter & Cheese",
                image: butterCheese,
                offer: 5
            },
            {
                id: 3,
                name: "Breakfast Cereal & Mixes",
                image: breakfastCerealMixes,
                offer: 14
            },
            {
                id: 4,
                name: "Jams, Honey & Spreads",
                image: jamsHoneySpreads,
                offer: 6
            }
        ]
    },
    {
        id: 7,
        name: "Noodles, Sauces & Instant Food",
        image: "",
        category: [
            {
                id: 1,
                name: "New Launches",
                image: newLaunchesNoodles,
                offer: 9
            },
            {
                id: 1,
                name: "Noodles & Sauces Best Offers",
                image: saucesKetchups,
                offer: 45
            },
            {
                id: 2,
                name: "Noodles & Sauces Best Offers",
                image: noodlesSaucesBestOffers,
                offer: 8
            },
            {
                id: 3,
                name: "Noodles & Vermicelli",
                image: noodlesVermicelli,
                offer: 12
            },
            {
                id: 4,
                name: "Jams, Honey & Spreads",
                image: jamsHoney,
                offer: 14
            },
            {
                id: 5,
                name: "Ready Made Meals & Mixes",
                image: readyMadeMealsMixes,
                offer: 22
            },
            {
                id: 6,
                name: "Pickles & Chutneys",
                image: picklesChutneys,
                offer: 40
            },
            {
                id: 7,
                name: "Baking & Dessert Items",
                image: bakingDessertItems,
                offer: 10
            },
        ]
    },
    {
        id: 8,
        name: "Baby Care",
        image: "",
        category: [
            {
                id: 1,
                name: "Baby Food",
                image: bBabyFood,
                offer: 0
            },
            {
                id: 2,
                name: "Diapers & Wipes",
                image: diapersWipes,
                offer: 33
            },
            {
                id: 3,
                name: "Baby Skin & Hair Care",
                image: babySkinHairCare,
                offer: 20
            },
            {
                id: 4,
                name: "Baby Accessories & More",
                image: babyAccessoriesMore,
                offer: 12
            }
        ]
    },
];