import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// CSS
import "./seeAll.css";

const SeeAll = ({ to, ...props }) => {
  return (
    <Link to={to} className={["km-see-all", props.className].join(" ")}>
      See All <span className="km-see-all km-see-all__icon">></span>
    </Link>
  );
};

SeeAll.propTypes = {
  to: PropTypes.string.isRequired,
};

export default SeeAll;
