import React from "react";
import PropTypes from "prop-types";
import util from "../../util/lib";

// CSS
import "./productCategory.css";

// Components
import Heading from "../core/Heading";
import SoftContainer from "../core/SoftContainer";
import SeeAll from "../core/SeeAll";

// constant
import { TABLET_VIEWPORT } from "../../constant/viewport";

const ProductCategory = ({ name, image, categories }) => {
  return (
    <SoftContainer>
      <div className="product-category-box">
        <div className="pl-3 d-flex">
          <Heading label={name} subHeading="This is sub heading" />
          {!(util.getViewport() >= TABLET_VIEWPORT) && (
            <SeeAll to="" className="pr-3 ml-auto" />
          )}
        </div>
        <div className="category-slider-container">
          {categories.map((category) => (
            <div
              key={category.id}
              className={[
                "category-slider-item",
                "m-1",
                "text-center",
                "cursor-pointer",
              ].join(" ")}
            >
              <img
                src={category.image}
                alt={category.name}
                style={{ objectFit: "contain" }}
              />
              <p>{category.name}</p>
              {category.offer ? (
                <span className="badge badge-success px-3 py-2">
                  {`up to ${category.offer}% off`}
                </span>
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      </div>
    </SoftContainer>
  );
};

ProductCategory.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
};

export default ProductCategory;
