// Phones - screens less than 768px wide
export const PHONE_VIEWPORT = 768;

// Tablets - screens equal to or less than 1024px wide
export const TABLET_VIEWPORT = 1024;

// Small laptops - screens equal to or greater than 992px wide
export const LAPTOP_VIEWPORT = 992;

// Laptops and desktops - screens equal to or greater than 1200px wide
export const DESKTOP_VIEWPORT = 1200;
