import React from "react";

// CSS
import "./masterBanner.css";

// Util
import util from "../../util/lib";

// Constant
import {
  TABLET_VIEWPORT,
  LAPTOP_VIEWPORT,
  PHONE_VIEWPORT,
} from "../../constant/viewport";

// Components
import Banner from "../core/Banner";
import Slider from "react-multi-carousel";
import SoftContainer from "../../components/core/SoftContainer";

const MasterBanner = ({ data }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: LAPTOP_VIEWPORT },
      items: 1,
    },
    tablet: {
      breakpoint: { max: LAPTOP_VIEWPORT, min: TABLET_VIEWPORT },
      items: 1,
    },
    mobile: {
      breakpoint: { max: PHONE_VIEWPORT, min: 0 },
      items: 1,
    },
  };

  const settings = {
    responsive: responsive,
    arrows: util.getViewport() >= TABLET_VIEWPORT,
    infinite: true,
    autoPlay: true,
    autoPlaySpeed: 5000,
    // centerMode: util.getViewport() >= LAPTOP_VIEWPORT,
    dotListClass: "master-slider-dots",
    itemClass: "master-banner",
  };

  return (
    <SoftContainer dividerTop={false}>
      <Slider {...settings}>
        {data &&
          data.length > 0 &&
          data.map((data) => <Banner key={data} data={data} />)}
      </Slider>
    </SoftContainer>
  );
};

export default MasterBanner;
