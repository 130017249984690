import React from "react";

//CSS
import "./softContainer.css";

const SoftContainer = ({
  children,
  divider = true,
  dividerTop = true,
  dividerBottom = true,
  carousel = false,
  ...props
}) => {
  const dividerClass = divider
    ? dividerTop && dividerBottom
      ? "my-2"
      : dividerTop
      ? "mt-2"
      : dividerBottom
      ? "mb-2"
      : ""
    : "";

  return (
    <div
      className={[
        "km-soft-container",
        carousel ? "px-0" : "",
        dividerClass,
        props.className,
      ].join(" ")}
    >
      {children}
    </div>
  );
};

export default SoftContainer;
