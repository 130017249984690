import React from "react";

// CSS
import "./banner.css";

// constant
import { TABLET_VIEWPORT } from "../../../constant/viewport";
// Util
import util from "../../../util/lib";

const Banner = ({ data }) => {
  const BANNER_HEIGHT =
    util.getViewport() < TABLET_VIEWPORT ? "165px" : "350px";

  return (
    <div className="banner-container" style={{ height: BANNER_HEIGHT }}>
      <img
        src={data}
        alt=""
        style={{
          width: "100%",
          height: "100%",
          objectFit: "fill",
          borderRadius: "8px",
        }}
      />
    </div>
  );
};

export default Banner;
