import React from "react";
import PropTypes from "prop-types";

// CSS
import "./subHeading.css";

const SubHeading = ({ children, ...props }) => {
  return <h3 className="km-sub-heading">{children}</h3>;
};

SubHeading.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SubHeading;
