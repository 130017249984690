import React from "react";
import "./searchbar.css";

const Searchbar = ({ width, placeholder, align }) => {
    return(
        <div className="search-bar">
            <form className={["d-flex", `${align ? "justify-content-" + align : ""}`].join(" ")}>
                <input className={["nav-search-bar", "mr-0", "ml-0", "pl-3", `w-${width || 100}`].join(" ")} type="search" placeholder={placeholder} />
                <button className="nav-search-icon">
                    <i className="fa fa-search icon-cog search-icon" aria-hidden="true"></i>
                </button>
            </form>
        </div>
    );
};

export default Searchbar;