import React from 'react';
import { Route, Switch } from "react-router-dom";

// Components
import Navbar from "./components/Navbar";

// Views
import LandingPage from "./views/LandingPage";

// CSS
import "react-multi-carousel/lib/styles.css"

function App() {
  return (
     <div>
         <Navbar />
         <Switch>
             <Route path="/" component={LandingPage} />
         </Switch>
     </div>
);
}

export default App;
